import { useAbility } from "@casl/vue"
import { useLocalStorage } from "@core/composable/useLocalStorage"
import { canNavigate } from '@layouts/plugins/casl'
import type { Router } from 'vue-router'

const route = useRoute()

export const setupGuards = (router: Router) => {
  // 👉 router.beforeEach
  // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  router.beforeEach(to => {
    /*
     * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
     * Examples of public routes are, 404, under maintenance, etc.
     */
    if (to.meta.public)
      return

    /**
     * Check if user is logged in by checking if token & user data exists in local storage
     * Feel free to update this logic to suit your needs
     */
    const isLoggedIn = !!(useLocalStorage('userData').value && useLocalStorage('accessToken').value)

    /*
      If user is logged in and is trying to access login like page, redirect to home
      else allow visiting the page
      (WARN: Don't allow executing further by return statement because next code will check for permissions)
     */
    if (to.meta.unauthenticatedOnly) {
      if (isLoggedIn)
        return '/'
      else
        return undefined
    }

    if (!canNavigate(to)) {
      console.log(`User cannot navigate to ${to.href}, required ability = ${to.meta.subject}, Got: ${JSON.stringify(useAbility())}`)

      /* eslint-disable indent */
      return isLoggedIn
        ? { name: 'not-authorized', query: { requiredPermission: to.meta.subject } }
        : {
            name: 'login',
            query: {
              ...to.query,
              to: to.fullPath !== '/' ? to.path : undefined,
            },
          }
      /* eslint-enable indent */
    }
  })
}

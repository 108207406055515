<script setup lang="ts">
import { useLocalStorage } from "@core/composable/useLocalStorage"

definePage({
  alias: '/pages/misc/not-authorized',
  meta: {
    layout: 'blank',
    public: true,
  },
})

const router = useRouter()
const ability = useAbility()

const userData = useLocalStorage<any>('userData')

const route = useRoute()

const logout = async () => {
  useLocalStorage('accessToken').value = null

  userData.value = null

  await router.push('/login')

  useLocalStorage('userAbilityRules').value = null

  ability.update([])
}
</script>

<template>
  <div class="misc-wrapper">
    <div class="text-center">
      <h4 class="text-h4 font-weight-medium mb-3">
        Insufficient Permission 🔐
      </h4>
      <p>
        You do not have permission to view this page.<br>
        Please contact your site administrator.
      </p>
      <p>
        Required permission: <strong>{{ route.query.requiredPermission }}</strong>
      </p>
    </div>

    <VBtn
      class="mt-2 mb-2"
      to="/"
      style="inline-size:150px"
    >
      <VIcon
        icon="tabler-home"
        start
      />
      Back Home
    </VBtn>

    <VBtn
      class="mt-2 mb-10"
      style="inline-size:150px"
      @click="logout"
    >
      Logout
      <VIcon
        icon="tabler-logout"
        end
      />
    </VBtn>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/misc.scss";
</style>
